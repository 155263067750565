
// Define multiple base URLs as an object
const BASE_URLS = {

  // Server link
  // main: 'http://localhost/websites/b2c_stripe/webadmin/api/',
  main: 'https://stripe.rathin.com/webadmin/api/',
  webUrl: '/',
  
  payUrl: 'https://stripe.rathin.com/',
  // payUrl: 'http://localhost/websites/b2c_stripe/',
  pk_live: 'pk_live_51PaEFlDoNoJ3VD6oQaF0vPtKLNxpi9PSxxIC4Jxs0i1Vdkc7VZNmZDPjWfA8yNL0wDmGgcxPMqpUteDhpb3qwC9m008OgrOEvg',
  tapApiUrl: "https://api.tap.company/v2",

};
export default BASE_URLS;
